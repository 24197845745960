import { 
  GET_CLUB,
  GET_CLUBS,
  GET_CLUBS_ALL,
  GET_CLUBS_COUNT,
  ADD_CLUB,
  DELETE_CLUB,
  UPDATE_CLUB,
  GET_FILTROS_CLUBS } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  clubs: [],
  selectedtiporangoId: 1,
  params: {},
  processing: false,
  filtros: {}
}

const prefix = 'clubs'

const clubs = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_CLUBS:
      return {
        ...state,
        data: action.clubs,
        total: action.total || 0,
        processing: false
      }
      case GET_CLUBS_ALL:
      return {
          ...state,
          clubs: action.clubs,
          processing: false
      }
     case GET_CLUB:
       return { ...state, selectedClub: action.selectedClub }
    case ADD_CLUB:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_CLUB:
      return { ...state, data: [...state.data].filter(club => club.id !== action.data)}
    case GET_CLUBS_COUNT:
      return { ...state, total: action.total }
    case UPDATE_CLUB:
      return { ...state, data: state.data.map(club => (club.id === action.data.id ? action.data : club)) }
    case GET_FILTROS_CLUBS:
      return {
        ...state,
        filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default clubs
