import { 
  GET_REPUESTO,
  GET_REPUESTOS,
  GET_REPUESTOS_ALL,
  GET_REPUESTOS_COUNT,
  ADD_REPUESTO,
  DELETE_REPUESTO,
  UPDATE_REPUESTO,
  GET_FILTROS_REPUESTOS } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  repuestos: [],
  selectedrepuestoId: 1,
  params: {},
  processing: false,
  filtros: {}
}

const prefix = 'repuestos'

const repuestos = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_REPUESTOS:
      return {
        ...state,
        data: action.repuestos,
        total: action.total || 0,
        processing: false
      }
      case GET_REPUESTOS_ALL:
      return {
          ...state,
          repuestos: action.repuestos,
          processing: false
      }
     case GET_REPUESTO:
       return { ...state, selectedRepuesto: action.selectedRepuesto }
    case ADD_REPUESTO:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_REPUESTO:
      return { ...state, data: [...state.data].filter(repuesto => repuesto.id !== action.data)}
    case GET_REPUESTOS_COUNT:
      return { ...state, total: action.total }
    case UPDATE_REPUESTO:
      return { ...state, data: state.data.map(repuesto => (repuesto.id === action.data.id ? action.data : repuesto)) }
    case GET_FILTROS_REPUESTOS:
      return {
        ...state,
        filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default repuestos
