import { 
  GET_PRODUCTO_PARA_CATALOGO,
  GET_PRODUCTOS_PARA_CATALOGO,
  GET_PRODUCTOS_PARA_CATALOGO_ALL,
  GET_PRODUCTOS_PARA_CATALOGO_COUNT,
  ADD_PRODUCTO_PARA_CATALOGO,
  DELETE_PRODUCTO_PARA_CATALOGO,
  UPDATE_PRODUCTO_PARA_CATALOGO,
  GET_FILTROS_PRODUCTOS_PARA_CATALOGO } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  gestionProductosCatalogo: [],
  selectedensambladoId: 1,
  params: {},
  processing: false,
  filtros: {}
}

const prefix = 'gestionProductosCatalogo'

const gestionProductosCatalogo = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_PRODUCTOS_PARA_CATALOGO:
      return {
        ...state,
        data: action.gestionProductosCatalogo,
        dataCatalogo: action.dataCatalogo,
        total: action.total || 0,
        processing: false
      }
      case GET_PRODUCTOS_PARA_CATALOGO_ALL:
      return {
          ...state,
          gestionProductosCatalogo: action.gestionProductosCatalogo,
          processing: false
      }
     case GET_PRODUCTO_PARA_CATALOGO:
       return { ...state, selectedProductoParaCatalogo: action.selectedProductoParaCatalogo }
    case ADD_PRODUCTO_PARA_CATALOGO:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_PRODUCTO_PARA_CATALOGO:
      return { ...state, data: [...state.data].filter(ensamblado => ensamblado.id !== action.data)}
    case GET_PRODUCTOS_PARA_CATALOGO_COUNT:
      return { ...state, total: action.total }
    case UPDATE_PRODUCTO_PARA_CATALOGO:
      return { ...state, data: state.data.map(ensamblado => (ensamblado.id === action.data.id ? action.data : ensamblado)) }
    case GET_FILTROS_PRODUCTOS_PARA_CATALOGO:
      return {
        ...state,
        filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default gestionProductosCatalogo
