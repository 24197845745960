import { CategoriasControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'categorias'

export const GET_CATEGORIA = `${prefix}/GET_DATA_CATEGORIA`
export const GET_CATEGORIAS = `${prefix}/GET_DATA`
export const GET_CATEGORIAS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_CATEGORIAS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_CATEGORIA = `${prefix}/ADD`
export const DELETE_CATEGORIA = `${prefix}/DELETE`
export const UPDATE_CATEGORIA = `${prefix}/UPDATE`
export const GET_FILTROS_CATEGORIAS = `${prefix}/GET_FILTROS_CATEGORIAS`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiCategorias = new CategoriasControllerApi(settings)

// ** Get all Data
export const getDataAll = params => {
  return async (dispatch, getState) => {
    try {

      const empresaId = getState().layout.selectedEmpresaId
      
      const filter =  {
        order: "nombre ASC",
        where: {['empresaId']: {eq: `${empresaId}`}
        }
      }

      const {data: dataCategorias} = await apiCategorias.categoriasControllerFind(JSON.stringify(filter))

      dispatch({
        type: GET_CATEGORIAS_ALL,
        categorias: dataCategorias
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getData = params => {
  return async (dispatch, getState) => {
    try {
      const {page = 1, perPage = 10, sortBy = "", q = "", searchNombre = "", grupoCategorias} = params
      const empresaId = getState().layout.selectedEmpresaId
      
      const filter =  {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          ['empresaId']: {eq: `${empresaId}`},
          ['or'] : 
          [{['nombre']: {like: `%${searchNombre}%`, options: 'i'}}]
        }
      }
      //
      //Si existe el padre modifico el filtro para que añada esa condición
      //
      if (grupoCategorias) {
        filter.where['padre'] = {eq: `${grupoCategorias}`}
      }

      const {data: dataCategorias} = await apiCategorias.categoriasControllerFind(JSON.stringify(filter))
      const {data: dataCategoriasCount} = await apiCategorias.categoriasControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_CATEGORIAS,
        categorias: dataCategorias,
        total: dataCategoriasCount.count || 0
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addCategoria = (categoria, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiCategorias.categoriasControllerCreate(categoria)
      displaySuccessMsg(intl.formatMessage({ id: 'Categoría Creada' }))
      dispatch({
         type: ADD_CATEGORIA,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateCategoria = (id, categoria, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiCategorias.categoriasControllerUpdateById(id, categoria)
      displaySuccessMsg(intl.formatMessage({ id: 'Categoría Actualizada' }))
      dispatch({
         type: UPDATE_CATEGORIA,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteCategoria = (categoriaId, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiCategorias.categoriasControllerDeleteById(categoriaId)
      displaySuccessMsg(intl.formatMessage({ id: 'Categoría Borrada' }))
       dispatch({
         type: DELETE_CATEGORIA,
         data: categoriaId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getCategoria = (categoria) => {
  return async (dispatch) => {
    try {
      const {data: dataCategoria} = await apiCategorias.categoriasControllerFindById(categoria)
      dispatch({
        type: GET_CATEGORIA,
        selectedCategoria: dataCategoria
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initCategoria = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_CATEGORIA,
      selectedCompany: null
    })
  }
}

export const getCategorias = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda } = params
      const { categoriaNivelUno_Gama, categoriaNivelDos_Categoria_Definida, categoriaNivelDos_Categoria_Agrupada, categoriaCodigo } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage

      const empresaId = getState().layout.selectedEmpresaId
      
      const filter = {
        where: {
          and: [
            { padre: '' },
            { codigo: { like: '%%' } },
            { empresaId: { eq: `${empresaId}` } }
          ]
        }
      }
      if (!categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Definida && !categoriaNivelDos_Categoria_Agrupada && !categoriaCodigo) {
        filter.where.and[0].padre = 'master'
      } else {
        if (categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Definida && !categoriaNivelDos_Categoria_Agrupada) {
          filter.where.and[0].padre = categoriaNivelUno_Gama
          filter.where.and[1].codigo.like = categoriaCodigo ? `%${categoriaCodigo}%` : '%%'
        }
        if (categoriaNivelUno_Gama && categoriaNivelDos_Categoria_Definida && !categoriaNivelDos_Categoria_Agrupada) {
          filter.where.and[0].padre = categoriaNivelDos_Categoria_Definida
          filter.where.and[1].codigo.like = categoriaCodigo ? `%${categoriaCodigo}%` : '%%'
        }
        if (!categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Definida && categoriaNivelDos_Categoria_Agrupada) {
          filter.where.and[0].padre = categoriaNivelDos_Categoria_Agrupada
          filter.where.and[1].codigo.like = categoriaCodigo ? `%${categoriaCodigo}%` : '%%'
        }
        if (!categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Definida && !categoriaNivelDos_Categoria_Agrupada && categoriaCodigo) {
          delete filter.where.and[0].padre
          filter.where.and[1].codigo.like = categoriaCodigo ? `%${categoriaCodigo}%` : '%%'
        }
      }

      const {data: dataCategorias} = await apiCategorias.categoriasControllerFind(JSON.stringify(filter))
      const listaDeCategorias = dataCategorias.filter(objeto => objeto.codigo !== 'master')
      const registrosPaginados = listaDeCategorias.slice(inicio, fin) // -> Filtra pagina
      
      dispatch({
        type: GET_CATEGORIAS,
        categorias: registrosPaginados,
        total: dataCategorias.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_CATEGORIAS,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerTodasLasCategorias = params => {
  return async (dispatch, getState) => {
    try {

      const empresaId = getState().layout.selectedEmpresaId
      
      const filter =  {
        order: "nombre ASC",
        where: {['empresaId']: {eq: `${empresaId}`}
        }
      }

      const {data: dataCategorias} = await apiCategorias.categoriasControllerFind(JSON.stringify(filter))

      dispatch({
        type: GET_CATEGORIAS_ALL,
        categorias: dataCategorias
      })

      return dataCategorias
    } catch (err) {
      console.error(err.message)
    }
  }
}

// C O M P R O B A R - S I - YA - E X I S T E - L A - C A T E G O R I A - A S O C I A D A - A L - P A D R E
export const ComprobarSiExisteCategoriaAsociadaAlPadre = (codigoCategoria, padre) => {
  return async () => {
    let resultJson
    try {
      const filter = {
        where: {
          codigo: codigoCategoria,
          padre
        }
      }
      return await apiCategorias.categoriasControllerFind(JSON.stringify(filter)).then(response => {
        if (response.status === 200) {
          resultJson = JSON.stringify({ status: "OK", categoria: response.data})
        } else {
          resultJson = JSON.stringify({ status: "ERROR", categoria: response.data})
        }
        return JSON.parse(resultJson)
    })
    } catch (err) {
      console.error(err.message)
      resultJson = JSON.stringify({ status: "ERROR", categoria: []})
      return JSON.parse(resultJson)
    }
  }
}
