import { ClubsControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'clubs'

export const GET_CLUB = `${prefix}/GET_DATA_clubs`
export const GET_CLUBS = `${prefix}/GET_DATA`
export const GET_CLUBS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_CLUBS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_CLUB = `${prefix}/ADD`
export const DELETE_CLUB = `${prefix}/DELETE`
export const UPDATE_CLUB = `${prefix}/UPDATE`
export const GET_FILTROS_CLUBS = `${prefix}/GET_FILTROS_CLUBS`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiClubs = new ClubsControllerApi(settings)

export const getClubs = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda } = params
      const { nombre, descripcion, filtroMarcas, filtroCategorias } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage

      // const empresaId = getState().layout.selectedEmpresaId
      
      const filter = {
        where: {
          and: []
        }
      }
      
      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ nombre: { like: nombre ? `%${nombre}%` : '%%' } })
      filter.where.and.push({ descripcion: { like: descripcion ? `%${descripcion}%` : '%%' } })
      filter.where.and.push({ filtroMarcas: { like: filtroMarcas ? `%${filtroMarcas}%` : '%%' } })
      filter.where.and.push({ filtroCategorias: { like: filtroCategorias ? `%${filtroCategorias}%` : '%%' } })

      const {data: dataClubs} = await apiClubs.clubsControllerFind(JSON.stringify(filter))
      const registrosPaginados = dataClubs.slice(inicio, fin) // -> Filtra pagina

      dispatch({
        type: GET_CLUBS,
        clubs: registrosPaginados,
        total: dataClubs.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_CLUBS,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addClub = (club, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiClubs.clubsControllerCreate(club)
      displaySuccessMsg(intl.formatMessage({ id: 'Club creado' }))
      dispatch({
         type: ADD_CLUB,
         data
       })
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateClub = (id, club, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiClubs.clubsControllerUpdateById(id, club)
      displaySuccessMsg(intl.formatMessage({ id: 'Club actualizado' }))
      dispatch({
         type: UPDATE_CLUB,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteClub = (clubId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
      const {data} = await apiClubs.clubsControllerDeleteById(clubId, datosRegistro.nombre)
      displaySuccessMsg(intl.formatMessage({ id: 'Club borrado' }))
       dispatch({
         type: DELETE_CLUB,
         data: clubId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getClub = (club) => {
  return async (dispatch) => {
    try {
      const {data: dataClub} = await apiClubs.clubsControllerFindById(club)
      dispatch({
        type: GET_CLUB,
        selectedClub: dataClub
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initClub = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_CLUB,
      selectedCompany: null
    })
  }
}