import { ProductosControllerApi, RepuestosControllerApi, SustitutosControllerApi, EnsambladosControllerApi, settings} from '@api/backend'
import Swal from "sweetalert2"
const prefix = 'logsincronizacion'

export const GET_LOG_SINCRONIZACION = `${prefix}/GET_DATA_logsincronizacion`
export const GET_LOGS_SINCRONIZACION = `${prefix}/GET_DATA`
export const GET_LOGS_SINCRONIZACION_ALL = `${prefix}/GET_DATA_ALL`
export const GET_LOGS_SINCRONIZACION_COUNT = `${prefix}/GET_DATA_COUNT`
export const GET_FILTROS_LOGS_SINCRONIZACION = `${prefix}/GET_FILTROS_LOGS_SINCRONIZACION`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiProductos = new ProductosControllerApi(settings)
const apiRepuestos = new RepuestosControllerApi(settings)
const apiSutitutos = new SustitutosControllerApi(settings)
const apiEnsamblados = new EnsambladosControllerApi(settings)

export const getLogsSincronizacion = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda } = params
      const { tablaLog, skuPrincipal, skuSecundario, fechaSincroConBC, conMotivoError } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage
      
      const filter = {
        where: {
          and: []
        }
      }

      if (tablaLog === 'productos') {
        filter.where.and.push({ nombre: { like: skuPrincipal ? `%${skuPrincipal}%` : '%%' } })
        filter.where.and.push({ sincronizarConBC: { eq: 'S' } })
      }
      if (tablaLog === 'repuestos' || tablaLog === 'sustitutos' || tablaLog === 'ensamblados') {
        filter.where.and.push({ skuPrincipal: { like: skuPrincipal ? `%${skuPrincipal}%` : '%%' } })
      }

      // Solo incluimos estos filtros si tienen valores proporcionados
      if (fechaSincroConBC) {
        const fechaInicio = `${fechaSincroConBC} 00:00:00`
        const fechaFin = `${fechaSincroConBC} 23:59:59`
        filter.where.and.push({ fechaSincronizacionConBC: { between: [fechaInicio, fechaFin] } })
      }

      if ((tablaLog !== '' && tablaLog !== undefined) && (conMotivoError === 'SI' || conMotivoError === '')) {
        filter.where.and.push({
          and: [
            { motivoErrorSincroConBC: { neq: null } },  // No null
            { motivoErrorSincroConBC: { neq: '' } }     // No vacío
          ]
        })
      } else {
        if (conMotivoError === 'NO') {
          filter.where.and.push({
            or: [
              { motivoErrorSincroConBC: { eq: null } },  // No null
              { motivoErrorSincroConBC: { eq: '' } }     // No vacío
            ]
          })
        }
      }

      let dataLogsSincronizacion = []
      let registrosPaginados = []

      switch (tablaLog) {
        case 'productos':
          const {data: dataProductos} = await apiProductos.productosControllerFind(JSON.stringify(filter))
          dataLogsSincronizacion = dataProductos
          registrosPaginados = dataLogsSincronizacion.slice(inicio, fin) // -> Filtra pagina
          break
        case 'repuestos':
          filter.where.and.push({ skuRepuesto: { like: skuSecundario ? `%${skuSecundario}%` : '%%' } })
          const {data: dataRepuestos} = await apiRepuestos.repuestosControllerFind(JSON.stringify(filter))
          dataLogsSincronizacion = dataRepuestos
          registrosPaginados = dataLogsSincronizacion.slice(inicio, fin) // -> Filtra pagina
          break
        case 'sustitutos':
          filter.where.and.push({ skuSustituto: { like: skuSecundario ? `%${skuSecundario}%` : '%%' } })
          const {data: dataSustitutos} = await apiSutitutos.sustitutosControllerFind(JSON.stringify(filter))
          dataLogsSincronizacion = dataSustitutos
          registrosPaginados = dataLogsSincronizacion.slice(inicio, fin) // -> Filtra pagina
          break
        case 'ensamblados':
          filter.where.and.push({ skuEnsamblado: { like: skuSecundario ? `%${skuSecundario}%` : '%%' } })
          const {data: dataEnsamblados} = await apiEnsamblados.ensambladosControllerFind(JSON.stringify(filter))
          dataLogsSincronizacion = dataEnsamblados
          registrosPaginados = dataLogsSincronizacion.slice(inicio, fin) // -> Filtra pagina
          break
        default:
          console.log('Estado desconocido.')
      }

      dispatch({
        type: GET_LOGS_SINCRONIZACION,
        logsincronizacion: registrosPaginados,
        total: dataLogsSincronizacion.length || 0
      })

      dispatch({
        type: GET_LOGS_SINCRONIZACION_ALL,
        logsincronizacion: dataLogsSincronizacion
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_LOGS_SINCRONIZACION,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getLogSincronizacion = (sustituto) => {
  return async (dispatch) => {
    try {
      const {data: dataLogSincronizacion} = await apiSutitutos.sustitutosControllerFindById(sustituto)
      dispatch({
        type: GET_LOG_SINCRONIZACION,
        selectedLogSincronizacion: dataLogSincronizacion
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initLogSincronizacion = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_LOG_SINCRONIZACION,
      selectedCompany: null
    })
  }
}

export const obtenerSkuPrincipales = () => {
  return async () => {
    try {
      const {data: listaSKUs} = await apiSutitutos.sustitutosControllerObtenerSkuPrincipales()
      return listaSKUs
    } catch (err) {
      console.error(err.message)
    }
  }
}

const armarDatosParaCSV = function (data, cabecera) {
  const cabeceraConPuntoYComa = cabecera.replaceAll(',', ';')
  const csvRows = ['id;'.concat(cabeceraConPuntoYComa)]
  let valuesStr = '', valueCampo, campo
  const campos = cabecera.split(",")

  Object.values(data).forEach(value => {
    valuesStr = value.id.toString()
    campos.forEach(el => {
      valueCampo = el === 'GLN' ? el : el[0].toLowerCase().concat(el.substring(1, el.length))
      campo = value[valueCampo]
      if (campo != null) {
        campo = campo.toString().replaceAll('\n', '').replaceAll(';', '')
      } else {
        campo = ''
      }
      valuesStr = valuesStr.concat(";").concat(campo)
    })
    csvRows.push(valuesStr)
  })
  return csvRows.join('\n')
}

const descargaFicheroCSV = function (data, tabla) {
  const csvContent = `\uFEFF${data}` //-> Para corregir el error de los caracteres con tílde
  const blob = new Blob([csvContent], { type: 'text/csv; charset=utf-8' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('download', tabla.concat('_PIM.csv'))
  a.click()
}

export const descargarCsvLogSincroConBC = (datos) => {
  let tabla = ''
  let cabecera = ''
  const tablaSeleccionada = localStorage.getItem('tablaDeLogAConsultar')
  
    switch (tablaSeleccionada) {
      case 'productos':
        tabla = 'productos'
        cabecera = 'nombre,fechaSincronizacionConBC,fechaEjecucionDeProcesoSincroBC,motivoErrorSincroConBC'
        break
      case 'repuestos':
        tabla = 'repuestos'
        cabecera = 'skuPrincipal,skuRepuesto,fechaSincronizacionConBC,fechaEjecucionDeProcesoSincroBC,motivoErrorSincroConBC'
        break
      case 'sustitutos':
        tabla = 'sustitutos'
        cabecera = 'skuPrincipal,skuSustituto,fechaSincronizacionConBC,fechaEjecucionDeProcesoSincroBC,motivoErrorSincroConBC'
        break
      case 'ensamblados':
        tabla = 'ensamblados'
        cabecera = 'skuPrincipal,skuEnsamblado,fechaSincronizacionConBC,fechaEjecucionDeProcesoSincroBC,motivoErrorSincroConBC'
        break
      default:
        tabla = 'productos'
        cabecera = 'nombre,fechaSincronizacionConBC,fechaEjecucionDeProcesoSincroBC,motivoErrorSincroConBC'
        break
    }
  return async () => {
    Swal.fire({
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading()
      }
    })
    try {
      switch (tabla) {
        case 'productos': descargaFicheroCSV(armarDatosParaCSV(datos.logsincronizacion, cabecera), tabla)
          break
        case 'sustitutos': descargaFicheroCSV(armarDatosParaCSV(datos.logsincronizacion, cabecera), tabla)
          break
        case 'ensamblados': descargaFicheroCSV(armarDatosParaCSV(datos.logsincronizacion, cabecera), tabla)
          break
        case 'repuestos': descargaFicheroCSV(armarDatosParaCSV(datos.logsincronizacion, cabecera), tabla)
          break
        default:
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Problema con el nombre de la tabla',
            showCloseButton: true
          })
      }
    } catch (err) {
      console.error(err.message)
    }
  }
}
