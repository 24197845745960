import { FamiliasControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'familias'

export const GET_FAMILIA = `${prefix}/GET_DATA_FAMILIA`
export const GET_FAMILIAS = `${prefix}/GET_DATA`
export const GET_FAMILIAS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_FAMILIAS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_FAMILIA = `${prefix}/ADD`
export const DELETE_FAMILIA = `${prefix}/DELETE`
export const UPDATE_FAMILIA = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiFamilias = new FamiliasControllerApi(settings)

// ** Get all Data
export const getDataAll = params => {
  return async (dispatch, getState) => {
    try {

      const empresaId = getState().layout.selectedEmpresaId
      
      const filter =  {
        order: "nombre ASC",
        where: {['empresaId']: {eq: `${empresaId}`}
        }
      }

      const {data: dataFamilias} = await apiFamilias.familiasControllerFind(JSON.stringify(filter))

      dispatch({
        type: GET_FAMILIAS_ALL,
        familias: dataFamilias
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}
export const getData = params => {
  return async (dispatch, getState) => {
    try {

      const {page = 1, perPage = 10, sortBy = "", q = "", search = "", grupoFamilias} = params
      const empresaId = getState().layout.selectedEmpresaId
    
      const filter =  {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          ['empresaId']: {eq: `${empresaId}`},
          ['or'] : [
            {['nombre']: {like: `%${search}%`, options: 'i'}},
            {['codigo']: {like: `%${search}%`, options: 'i'}}
          ]
        }
      }
      //
      //Si existe el padre modifico el filtro para que añada esa condición
      //
      if (grupoFamilias) {
        filter.where = {
          ['empresaId']: {eq: `${empresaId}`},
          ['padre']: {eq: `${grupoFamilias}`},
          ['or'] : [
            {['nombre']: {like: `%${search}%`, options: 'i'}},
            {['codigo']: {like: `%${search}%`, options: 'i'}}
          ]
        }
      }
      const {data: dataFamilias} = await apiFamilias.familiasControllerFind(JSON.stringify(filter))
      const {data: dataFamiliasCount} = await apiFamilias.familiasControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_FAMILIAS,
        familias: dataFamilias,
        total: dataFamiliasCount.count || 0
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addFamilia = (familia, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiFamilias.familiasControllerCreate(familia)
      displaySuccessMsg(intl.formatMessage({ id: 'Familia creada' }))
      dispatch({
         type: ADD_FAMILIA,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateFamilia = (id, familia, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiFamilias.familiasControllerUpdateById(id, familia)
      displaySuccessMsg(intl.formatMessage({ id: 'Familia actualizada' }))
      dispatch({
         type: UPDATE_FAMILIA,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteFamilia = (familiaId, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiFamilias.familiasControllerDeleteById(familiaId)
      displaySuccessMsg(intl.formatMessage({ id: 'Familia borrada' }))
      dispatch({
         type: DELETE_FAMILIA,
         data: familiaId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getFamilia = (familia) => {
  return async (dispatch) => {
    try {
      const {data: dataFamilia} = await apiFamilias.familiasControllerFindById(familia)
      dispatch({
        type: GET_FAMILIA,
        selectedFamilia: dataFamilia
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initFamilia = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_FAMILIA,
      selectedCompany: null
    })
  }
}
