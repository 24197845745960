import { SustitutosControllerApi, CatalogosControllerApi, CatalogosProductosControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'catalogos'

export const GET_CATALOGO = `${prefix}/GET_DATA_catalogos`
export const GET_CATALOGOS = `${prefix}/GET_DATA`
export const GET_CATALOGOS_ALL = `${prefix}/GET_DATA_ALL` 
export const GET_CATALOGOS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_CATALOGO = `${prefix}/ADD`
export const DELETE_CATALOGO = `${prefix}/DELETE`
export const UPDATE_CATALOGO = `${prefix}/UPDATE`
export const GET_FILTROS_CATALOGOS = `${prefix}/GET_FILTROS_CATALOGOS`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiSutitutos = new SustitutosControllerApi(settings)
const apiCatalogos = new CatalogosControllerApi(settings)
const apiCatalogosProductos = new CatalogosProductosControllerApi(settings)

export const getCatalogos = params => {
  return async (dispatch) => {
    try {

      const { page = 1, perPage = 10, filtrosBusqueda } = params
      const { empresa, codigo, nombre, anyo } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage
      
      const filter = {
        where: {
          and: []
        },
        limit: perPage,
        skip: ((page - 1) * perPage)
      }
      const filterContador = { empresaId: { eq: empresa ? `${empresa}` : '%%' } }
      // Siempre incluimos la empresa con la que estamos trabajando
      filter.where.and.push({ empresaId: { eq: empresa ? `${empresa}` : '%%' } })
      
      // Solo incluimos estos filtros si tienen valores proporcionados
      if (codigo) {
        filter.where.and.push({ codigo: { like: codigo ? `%${codigo}%` : '%%' } })
      }
      if (nombre) {
        filter.where.and.push({ nombre: { like: nombre ? `%${nombre}%` : '%%' } })
      }
      if (anyo) {
        filter.where.and.push({ anyo: { like: anyo ? `%${anyo}%` : '%%' } })
      }
      const {data: dataCatalogos} = await apiCatalogos.catalogosControllerFind(JSON.stringify(filter))
      const {data: contador} = await apiCatalogos.catalogosControllerCount(JSON.stringify(filterContador))
      
      dispatch({
        type: GET_CATALOGOS,
        catalogos: dataCatalogos,
        total: contador.count || 0
        //total: cantidadDeRegistros.count || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_CATALOGOS,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addCatalogo = (catalogo, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiCatalogos.catalogosControllerCreate(catalogo)
      displaySuccessMsg(intl.formatMessage({ id: 'Catalogo creado' }))
      dispatch({
         type: ADD_CATALOGO,
         data
       })
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateCatalogo = (id, catalogo, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiCatalogos.catalogosControllerUpdateById(id, catalogo)
      displaySuccessMsg(intl.formatMessage({ id: 'Catalogo actualizado' }))
      dispatch({
         type: UPDATE_CATALOGO,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

  export const deleteCatalogo = (catalogoId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
       // 1- Eliminar todos los productos relacionados con el catálogo
      await apiCatalogosProductos.catalogosProductosControllerEliminarProductosPorCatalogo(catalogoId)
      // 2- Eliminar el catálogo
      const {data} = await apiCatalogos.catalogosControllerDeleteById(catalogoId, datosRegistro.Id)
      displaySuccessMsg(intl.formatMessage({ id: 'Catálogo borrado' }))
       dispatch({
         type: DELETE_CATALOGO,
         data: catalogoId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getCatalogo = (catalogo) => {
  return async (dispatch) => {
    try {
      const {data: dataCatalogo} = await apiCatalogos.catalogosControllerFindById(catalogo)
      dispatch({
        type: GET_CATALOGO,
        selectedCatalogo: dataCatalogo
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initCatalogo = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_CATALOGO,
      selectedCompany: null
    })
  }
}

