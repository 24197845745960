import { SustitutosControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'sustitutos'

export const GET_SUSTITUTO = `${prefix}/GET_DATA_sustitutos`
export const GET_SUSTITUTOS = `${prefix}/GET_DATA`
export const GET_SUSTITUTOS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_SUSTITUTOS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_SUSTITUTO = `${prefix}/ADD`
export const DELETE_SUSTITUTO = `${prefix}/DELETE`
export const UPDATE_SUSTITUTO = `${prefix}/UPDATE`
export const GET_FILTROS_SUSTITUTOS = `${prefix}/GET_FILTROS_SUSTITUTOS`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiSutitutos = new SustitutosControllerApi(settings)

export const getSustitutos = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda, listaDeTodosLosProductos } = params
      const { skuPrincipal, skuSustituto, descripcion, fechaInicio, fechaFin, intercambiable } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage

      // const empresaId = getState().layout.selectedEmpresaId
      
      const filter = {
        where: {
          and: []
        }
      }
      
      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ skuPrincipal: { like: skuPrincipal ? `%${skuPrincipal}%` : '%%' } })
      filter.where.and.push({ skuSustituto: { like: skuSustituto ? `%${skuSustituto}%` : '%%' } })
      filter.where.and.push({ descripcion: { like: descripcion ? `%${descripcion}%` : '%%' } })
      
      // Solo incluimos estos filtros si tienen valores proporcionados
      if (fechaInicio) {
        filter.where.and.push({ fechaInicio: { eq: `${fechaInicio}` } })
      }
      if (fechaFin) {
        filter.where.and.push({ fechaFin: { eq: `${fechaFin}` } })
      }
      if (intercambiable) {
        filter.where.and.push({ intercambiable: { like: `%${intercambiable}%` } })
      }


      const {data: dataSustitutos} = await apiSutitutos.sustitutosControllerFind(JSON.stringify(filter))
      const registrosPaginados = dataSustitutos.slice(inicio, fin) // -> Filtra pagina
      
      const agregarTitulos = (lstTodosLosProductos, lstRegistros) => {
        return lstRegistros.map(item => {
          const objSKUPrincipal = lstTodosLosProductos.find(obj => obj.sku === item.skuPrincipal)
          const objSKUSustituto = lstTodosLosProductos.find(obj => obj.sku === item.skuSustituto)
      
          return {
            ...item,
            tituloSkuPrincipal: objSKUPrincipal ? objSKUPrincipal.titulo : '',
            tituloSkuSustituto: objSKUSustituto ? objSKUSustituto.titulo : ''
          }
        })
      }
      
      const registrosPaginadosConTitulos = agregarTitulos(listaDeTodosLosProductos, registrosPaginados)

      dispatch({
        type: GET_SUSTITUTOS,
        sustitutos: registrosPaginadosConTitulos,
        total: dataSustitutos.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_SUSTITUTOS,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addSustituto = (sustituto, muestroMensaje, intl) => {
  return async (dispatch) => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      sustituto.fechaCreacion = fechaYHoraActual
      sustituto.fechaModificacion = fechaYHoraActual
      const {data} = await apiSutitutos.sustitutosControllerCreate(sustituto)
      if (muestroMensaje) {
        displaySuccessMsg(intl.formatMessage({ id: 'Sustituto creado' }))
      }
      dispatch({
         type: ADD_SUSTITUTO,
         data
       })
      return data
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateSustituto = (id, sustituto, muestroMensaje, intl) => {
  return async (dispatch) => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      sustituto.fechaModificacion = fechaYHoraActual
      const {data} = await apiSutitutos.sustitutosControllerUpdateById(id, sustituto)
      if (muestroMensaje) {
        displaySuccessMsg(intl.formatMessage({ id: 'Sustituto actualizado' }))
      }
      
      dispatch({
         type: UPDATE_SUSTITUTO,
         data
       })
       return data
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteSustituto = (sustitutoId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
      const {data} = await apiSutitutos.sustitutosControllerDeleteById(sustitutoId, datosRegistro.skuPrincipal)
      displaySuccessMsg(intl.formatMessage({ id: 'Sustituto borrado' }))
       dispatch({
         type: DELETE_SUSTITUTO,
         data: sustitutoId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getSustituto = (sustituto) => {
  return async (dispatch) => {
    try {
      const {data: dataSustituto} = await apiSutitutos.sustitutosControllerFindById(sustituto)
      dispatch({
        type: GET_SUSTITUTO,
        selectedSustituto: dataSustituto
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initSustituto = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_SUSTITUTO,
      selectedCompany: null
    })
  }
}

export const obtenerSkuPrincipales = () => {
  return async () => {
    try {
      const {data: listaSKUs} = await apiSutitutos.sustitutosControllerObtenerSkuPrincipales()
      return listaSKUs
    } catch (err) {
      console.error(err.message)
    }
  }
}
