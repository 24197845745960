import { 
  GET_CATALOGO,
  GET_CATALOGOS,
  GET_CATALOGOS_ALL,
  GET_CATALOGOS_COUNT,
  ADD_CATALOGO,
  DELETE_CATALOGO,
  UPDATE_CATALOGO,
  GET_FILTROS_CATALOGOS } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  catalogos: {},
  params: {},
  processing: false,
  selectedCatalogo: null,
  filtros: {}
}

const prefix = 'catalogos'

const catalogos = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_CATALOGOS:
      return {
        ...state,
        data: action.catalogos,
        total: action.total || 0,
        processing: false
      }
      case GET_CATALOGOS_ALL:
      return {
          ...state,
          catalogos: action.catalogos,
          processing: false
      }
     case GET_CATALOGO:
       return { ...state, selectedCatalogo: action.selectedCatalogo }
    case ADD_CATALOGO:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_CATALOGO:
      return { ...state, data: [...state.data].filter(catalogo => catalogo.id !== action.data)}
    case GET_CATALOGOS_COUNT:
      return { ...state, total: action.total }
    case UPDATE_CATALOGO:
      return { ...state, data: state.data.map(catalogo => (catalogo.id === action.data.id ? action.data : catalogo)) }
    case GET_FILTROS_CATALOGOS:
      return {
        ...state,
        filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default catalogos
