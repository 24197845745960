import { SustitutosControllerApi, RepuestosControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'repuestos'

export const GET_REPUESTO = `${prefix}/GET_DATA_repuestos`
export const GET_REPUESTOS = `${prefix}/GET_DATA`
export const GET_REPUESTOS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_REPUESTOS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_REPUESTO = `${prefix}/ADD`
export const DELETE_REPUESTO = `${prefix}/DELETE`
export const UPDATE_REPUESTO = `${prefix}/UPDATE`
export const GET_FILTROS_REPUESTOS = `${prefix}/GET_FILTROS_REPUESTOS`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiSutitutos = new SustitutosControllerApi(settings)
const apiRepuestos = new RepuestosControllerApi(settings)

async function agregarCantidadDeVecesQueSeRepiteElSKURepuesto(lstRegistros) {
  const registrosConPromesas = lstRegistros.map(async item => {
    const filtroJr = {
      where: {
      }
    }
    filtroJr.where['skuRepuesto'] = { eq: `${item.skuRepuesto}` }
    const { data: cantidadDeRegistros } = await apiRepuestos.repuestosControllerCount(JSON.stringify(filtroJr.where))

    return {
      ...item,
      cantidadRepetidosSKURepuesto: cantidadDeRegistros.count
    }
  })

  // Espera a que todas las promesas se resuelvan
  const registrosConCantidad = await Promise.all(registrosConPromesas)
  return registrosConCantidad
}

export const getRepuestos = params => {
  return async (dispatch) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda, listaDeTodosLosProductos } = params
      const { skuPrincipal, skuRepuesto, descripcion, posicion, fechaInicio, fechaFin } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage
      
      const filter = {
        // offset: (page - 1) * perPage, // inicio
        // limit: perPage,
        // skip: ((page - 1) * perPage),
        where: {
          and: []
        }
      }

      // const filtroJr = {
      //   where: {
      //   }
      // }

      // filtroJr.where['skuRepuesto'] = { eq: "1104462" }
      
      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ skuPrincipal: { like: skuPrincipal ? `${skuPrincipal}` : '%%' } })
      filter.where.and.push({ skuRepuesto: { like: skuRepuesto ? `${skuRepuesto}` : '%%' } })
      filter.where.and.push({ descripcion: { like: descripcion ? `%${descripcion}%` : '%%' } })
      
      // Solo incluimos estos filtros si tienen valores proporcionados
      if (fechaInicio) {
        filter.where.and.push({ fechaInicio: { eq: `${fechaInicio}` } })
      }
      if (fechaFin) {
        filter.where.and.push({ fechaFin: { eq: `${fechaFin}` } })
      }
      if (posicion) {
        filter.where.and.push({ posicion: { eq: `${posicion}` } })
      }


      const {data: dataRepuestos} = await apiRepuestos.repuestosControllerFind(JSON.stringify(filter))
      // const { data: cantidadDeRegistros } = await apiRepuestos.repuestosControllerCount(JSON.stringify(filtroJr.where))
      // console.log(cantidadDeRegistros)
      const registrosPaginados = dataRepuestos.slice(inicio, fin) // -> Filtra pagina

      // Llamada a la función
      const registrosPaginadosConCantVecesRepetido = await agregarCantidadDeVecesQueSeRepiteElSKURepuesto(registrosPaginados)

      const agregarTitulos = (lstTodosLosProductos, lstRegistros) => {
        return lstRegistros.map(item => {
          const objSKUPrincipal = lstTodosLosProductos.find(obj => obj.sku === item.skuPrincipal)
          const objSKURepuesto = lstTodosLosProductos.find(obj => obj.sku === item.skuRepuesto)
      
          return {
            ...item,
            tituloSkuPrincipal: objSKUPrincipal ? objSKUPrincipal.titulo : '',
            tituloSkuRepuesto: objSKURepuesto ? objSKURepuesto.titulo : ''
          }
        })
      }
      
      const registrosPaginadosConTitulos = agregarTitulos(listaDeTodosLosProductos, registrosPaginadosConCantVecesRepetido)

      dispatch({
        type: GET_REPUESTOS,
        repuestos: registrosPaginadosConTitulos,
        total: dataRepuestos.length || 0
        //total: cantidadDeRegistros.count || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_REPUESTOS,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addRepuesto = (repuesto, intl) => {
  return async (dispatch) => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      repuesto.fechaCreacion = fechaYHoraActual
      repuesto.fechaModificacion = fechaYHoraActual
      const {data} = await apiRepuestos.repuestosControllerCreate(repuesto)
      displaySuccessMsg(intl.formatMessage({ id: 'Repuesto creado' }))
      dispatch({
         type: ADD_REPUESTO,
         data
       })
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateRepuesto = (id, repuesto, intl) => {
  return async (dispatch) => {
    try {
      const fechaYHoraActual = new Date().toISOString()
      repuesto.fechaModificacion = fechaYHoraActual
      const {data} = await apiRepuestos.repuestosControllerUpdateById(id, repuesto)
      displaySuccessMsg(intl.formatMessage({ id: 'Repuesto actualizado' }))
      dispatch({
         type: UPDATE_REPUESTO,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteRepuesto = (repuestoId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
      const {data} = await apiRepuestos.repuestosControllerDeleteById(repuestoId, datosRegistro.skuPrincipal)
      displaySuccessMsg(intl.formatMessage({ id: 'Repuesto borrado' }))
       dispatch({
         type: DELETE_REPUESTO,
         data: repuestoId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getRepuesto = (repuesto) => {
  return async (dispatch) => {
    try {
      const {data: dataRepuesto} = await apiRepuestos.repuestosControllerFindById(repuesto)
      dispatch({
        type: GET_REPUESTO,
        selectedRepuesto: dataRepuesto
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initRepuesto = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_REPUESTO,
      selectedCompany: null
    })
  }
}

export const obtenerSkuPrincipales = () => {
  return async () => {
    try {
      const {data: listaSKUs} = await apiSutitutos.sustitutosControllerObtenerSkuPrincipales()
      return listaSKUs
    } catch (err) {
      console.error(err.message)
    }
  }
}
