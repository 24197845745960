import { IconosControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'
import ComingSoon from '@src/views/pages/misc/ComingSoon'

const prefix = 'iconos'

export const GET_ICONO = `${prefix}/GET_DATA_icono`
export const GET_ICONOS = `${prefix}/GET_DATA`
export const GET_ICONOS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_ICONOS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_ICONO = `${prefix}/ADD`
export const DELETE_ICONO = `${prefix}/DELETE`
export const UPDATE_ICONO = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiIconos = new IconosControllerApi(settings)

// ** Get all Data

export const getData = params => {
  return async (dispatch, getState) => {
    try {
      const {page = 1, perPage = 10, sortBy = "", q = ""} = params
      const empresaId = getState().layout.selectedEmpresaId

      const filter =  {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          ['empresaId']: {eq: `${empresaId}`},
          ['or'] : [
            // Buscará valores coincidentes con cualquiera de estos campos
            {['nombre']: {like: `%${q}%`, options: 'i'}},
            {['posiblesValores']: {like: `%${q}%`, options: 'i'}}
          ]
        }
      }
      
      const {data: dataIconos} = await apiIconos.iconosControllerFind(JSON.stringify(filter))
      const {data: dataIconosCount} = await apiIconos.iconosControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_ICONOS,
        iconos: dataIconos,
        total: dataIconosCount.count || 0
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addIcono = (icono, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiIconos.iconosControllerCreate(icono)
      displaySuccessMsg(intl.formatMessage({ id: 'Icono creada' }))
      dispatch({
         type: ADD_ICONO,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateIcono = (id, icono, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiIconos.iconosControllerUpdateById(id, icono)
      displaySuccessMsg(intl.formatMessage({ id: 'Icono actualizada' }))
      dispatch({
         type: UPDATE_ICONO,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteIcono = (iconoId, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiIconos.iconosControllerDeleteById(iconoId)
      displaySuccessMsg(intl.formatMessage({ id: 'Icono borrado' }))
       dispatch({
         type: DELETE_ICONO,
         data: iconoId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getIcono = (icono) => {
  return async (dispatch) => {
    try {
      const {data: dataicono} = await apiIconos.iconosControllerFindById(icono)
      dispatch({
        type: GET_ICONO,
        selectedIcono: dataicono
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initIcono = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_ICONO,
      selectedCompany: null
    })
  }
}
