import {
  CatalogosProductosControllerApi, ProductosControllerApi, ProductoGeneralControllerApi,
  SituacionesControllerApi, SustitutosControllerApi, ProductoDatosCatalogoControllerApi, settings
} from '@api/backend'
import { devuelveBasePath } from '@src/utility/Utils'

const prefix = 'gestionProductosCatalogo'

export const GET_PRODUCTO_PARA_CATALOGO = `${prefix}/GET_DATA_productosParaCatalogo`
export const GET_PRODUCTOS_PARA_CATALOGO = `${prefix}/GET_DATA`
export const GET_PRODUCTOS_PARA_CATALOGO_ALL = `${prefix}/GET_DATA_ALL`
export const GET_PRODUCTOS_PARA_CATALOGO_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_PRODUCTO_PARA_CATALOGO = `${prefix}/ADD`
export const DELETE_PRODUCTO_PARA_CATALOGO = `${prefix}/DELETE`
export const UPDATE_PRODUCTO_PARA_CATALOGO = `${prefix}/UPDATE`
export const GET_FILTROS_PRODUCTOS_PARA_CATALOGO = `${prefix}/GET_FILTROS_PRODUCTOS_PARA_CATALOGO`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiSutitutos = new SustitutosControllerApi(settings)
const apiProductos = new ProductosControllerApi(settings)
const apiProductosGeneral = new ProductoGeneralControllerApi(settings)
const apiCatalogoProductos = new CatalogosProductosControllerApi(settings)
const apiSituaciones = new SituacionesControllerApi(settings)
const apiProductoDatosCatalogo = new ProductoDatosCatalogoControllerApi(settings)

// Función para combinar las listas por productoId y id
const combinarListas = (detalles, productos) => {
  return detalles.map(detalle => {
    // Encontrar el producto en la segunda lista que coincida por productoId
    const producto = productos.find(prod => prod.id === detalle.productoId)

    // Si encuentra un producto, combina los campos de ambas listas
    if (producto) {
      return {
        id: detalle.productoId,
        sku: detalle.sku,
        ean: detalle.ean,
        marca: detalle.marca,
        descripcion: detalle.descripcion,
        categoria: producto.categorias,
        imagen: producto.imagen ? `${devuelveBasePath()}/${producto.imagen}` : '',
        estadoReferencia: detalle.estadoReferencia
      }
    }

    // Si no encuentra un producto, puedes devolver null o filtrar luego estos casos
    return null
  }).filter(item => item !== null) // Filtrar los casos donde no hubo coincidencia
}

export const getProductosParaCatalogo = params => {
  return async (dispatch, getState) => {
    try {
      const { catalogoId, listadoTodasLasCategorias, filtrosBusqueda } = params
      const { skuPrincipal, situacion, marca, referenciaAgrupacion,
        categoriaNivelUno_Gama, categoriaNivelDos_Categoria_Agrupada, categoriaNivelDos_Categoria_Definida } = filtrosBusqueda || {}

      let registrosFinales = []

      const filterProductoGeneral = {
        where: {
          and: []
        }
      }
      const filterProducto = {
        where: {
          and: []
        }
      }

      const filterCatalogoProductos = {
        where: {
          and: []
        }
      }

      if (skuPrincipal) {
        filterProductoGeneral.where.and.push({ sku: { eq: skuPrincipal } })
      }
      if (situacion) {
        filterProductoGeneral.where.and.push({ estadoReferencia: { eq: situacion } })
      }
      if (marca) {
        filterProductoGeneral.where.and.push({ marca: { eq: marca } })
      }
      if (referenciaAgrupacion) {
        filterProductoGeneral.where.and.push({ referenciaAgrupacion: { eq: referenciaAgrupacion } })
      }

      // Si filtra unicamente por categoria nivel 1 (GAMA)
      //-> Buscamos las categorias nivel 2 (CATEGORIA) para obtener las categorias nivel 3 (SUBCATEGORIA)
      if (categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Agrupada && !categoriaNivelDos_Categoria_Definida) {
        //Obtener las categorias nivel 1 (GAMA)
        const listNivelUno_abuelo = listadoTodasLasCategorias.filter((categoria) => categoria.padre === categoriaNivelUno_Gama)
        //Obtener las categorias nivel 2 (CATEGORIA)
        const listNivelDos_padre = listadoTodasLasCategorias.filter(categoriaNivelDos => listNivelUno_abuelo.some(categoriaNivelUno => categoriaNivelUno.codigo === categoriaNivelDos.padre))
        // Extraemos los códigos en una lista
        const listaCodigos = listNivelDos_padre.map(objeto => objeto.codigo)
        filterProducto.where.and.push({
          categorias: { inq: listaCodigos }  // Filtra por los códigos de la lista
        })
      }

      // Si filtra por categoria nivel 1 (GAMA) y categoria nivel 2 (CATEGORIA) 
      //-> Directamente buscamos por la categoria nivel 2 para obtener las categorias nivel 3 (SUBCATEGORIA)
      if ((categoriaNivelUno_Gama && categoriaNivelDos_Categoria_Definida) || (categoriaNivelDos_Categoria_Agrupada && !categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Definida)) {
        //Obtener directamente las categorias nivel 3 (SUBCATEGORIA)
        const categoriaNivelDos = categoriaNivelDos_Categoria_Definida || categoriaNivelDos_Categoria_Agrupada
        const listNivelTres_hijo = listadoTodasLasCategorias.filter((categoria) => categoria.padre === categoriaNivelDos)
        // Extraemos los códigos en una lista
        const listaCodigos = listNivelTres_hijo.map(objeto => objeto.codigo)
        filterProducto.where.and.push({
          categorias: { inq: listaCodigos }  // Filtra por los códigos de la lista
        })
      }

      if (skuPrincipal || situacion || marca || referenciaAgrupacion || categoriaNivelUno_Gama || categoriaNivelDos_Categoria_Agrupada || categoriaNivelDos_Categoria_Definida) {
        let dataProductosGeneral = []
        let dataProductos = []

        if (skuPrincipal || situacion || marca || referenciaAgrupacion) { // Se consulta la API con los filtros
          ({ data: dataProductosGeneral } = await apiProductosGeneral.productoGeneralControllerFind(JSON.stringify(filterProductoGeneral)))
        } else { // Se consulta la API sin filtros
          ({ data: dataProductosGeneral } = await apiProductosGeneral.productoGeneralControllerFind())
        }

        if (categoriaNivelUno_Gama || categoriaNivelDos_Categoria_Definida || categoriaNivelDos_Categoria_Agrupada) { // Se consulta la API con los filtros
          ({ data: dataProductos } = await apiProductos.productosControllerFind(JSON.stringify(filterProducto)))
        } else { // Se consulta la API sin filtros
          ({ data: dataProductos } = await apiProductos.productosControllerFind())
        }

        // Combinar las listas
        const listaCombinada = combinarListas(dataProductosGeneral, dataProductos)
        // Obtener solo los IDs
        const listaDeIds = listaCombinada.map(producto => producto.id)
        if (listaDeIds.length > 0) {
          // Filtra el catálogo con los productos que coinciden con los IDs
          filterCatalogoProductos.where.and.push({
            productoId: { inq: listaDeIds },
            catalogoId
          })
          const { data: dataCatalogoProductos } = await apiCatalogoProductos.catalogosProductosControllerFind(JSON.stringify(filterCatalogoProductos))

          if (dataCatalogoProductos.length > 0) {
            // Obtenemos solo los ids que ya están en el catálogo
            const idsExistentes = dataCatalogoProductos.map(producto => producto.productoId)
            // Filtramos la lista combinada excluyendo los productos que ya están en el catálogo
            registrosFinales = listaCombinada.filter(producto => !idsExistentes.includes(producto.id))
          } else {
            // Si no hay coincidencias en el catálogo, retornamos la lista completa
            registrosFinales = listaCombinada
          }
        } else {
          // Si no hay IDs en la lista, retornamos un array vacío
          registrosFinales = []
        }
      }
      const { data: dataProductosCatalgo } = await apiProductoDatosCatalogo.productoDatosCatalogoControllerFind()

      dispatch({
        type: GET_PRODUCTOS_PARA_CATALOGO,
        gestionProductosCatalogo: registrosFinales,
        dataCatalogo: dataProductosCatalgo,
        total: registrosFinales.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_PRODUCTOS_PARA_CATALOGO,
        filtros: filtrosBusqueda
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addProductosACatalogo = (listaProductosCatalogo) => {
  return async () => {
    try {
      // Llamo a la API para agregar múltiples productos al catálogo
      const { data } = await apiCatalogoProductos.catalogosProductosControllerAgregarMultiplesProductosAlCatalogo(listaProductosCatalogo)

      // Verifico si la respuesta contiene los datos esperados
      if (data && Array.isArray(data) && data.length > 0) {
        // Si la inserción fue exitosa y se devolvieron datos
        return { status: 'OK', message: 'Productos agregados exitosamente', data }
      } else {
        // Si la respuesta no tiene datos o no es un arreglo
        return { status: 'ERROR', message: 'No se insertaron productos, respuesta inesperada' }
      }
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
      return { status: 'ERROR', message: `Error al agregar productos: ${err.message}` }
    }
  }
}

// Función para filtrar productos según la lógica de categorías
const filtrarProductosPorCategoria = (productos, listadoTodasLasCategorias, categoriaNivelUno_Gama, categoriaNivelDos_Categoria_Definida, categoriaNivelDos_Categoria_Agrupada) => {

  // Si no hay ningún filtro, devolvemos todos los productos
  if (!categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Definida && !categoriaNivelDos_Categoria_Agrupada) {
    return productos
  }

  let listaCodigos = []

  // filtra únicamente por categoría nivel 1 (GAMA)
  if (categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Agrupada && !categoriaNivelDos_Categoria_Definida) {
    // Obtener las categorías de nivel 1 (GAMA)
    const listNivelUno_abuelo = listadoTodasLasCategorias.filter(categoria => categoria.padre === null && categoria.codigo === categoriaNivelUno_Gama)
    // Obtener las categorías de nivel 2 (CATEGORIA)
    const listNivelDos_padre = listadoTodasLasCategorias.filter(categoriaNivelDos => listNivelUno_abuelo.some(categoriaNivelUno => categoriaNivelUno.codigo === categoriaNivelDos.padre))
    // Extraemos los códigos de nivel 2
    listaCodigos = listNivelDos_padre.map(objeto => objeto.codigo)
  }

  // filtra por categoría nivel 1 (GAMA) y categoría nivel 2 (CATEGORIA)
  if ((categoriaNivelUno_Gama && categoriaNivelDos_Categoria_Definida) || (categoriaNivelDos_Categoria_Agrupada && !categoriaNivelUno_Gama && !categoriaNivelDos_Categoria_Definida)) {
    // Obtener directamente las categorías de nivel 3 (SUBCATEGORIA)
    const categoriaNivelDos = categoriaNivelDos_Categoria_Definida || categoriaNivelDos_Categoria_Agrupada
    const listNivelTres_hijo = listadoTodasLasCategorias.filter(categoria => categoria.padre === categoriaNivelDos)
    // Extraemos los códigos de nivel 3
    listaCodigos = listNivelTres_hijo.map(objeto => objeto.codigo)
  }

  // Filtrar productos según las categorías obtenidas
  return productos.filter(producto => listaCodigos.includes(producto.categorias))
}

export const getProductosDelCatalogo = params => {
  return async (dispatch, getState) => {
    try {
      const { catalogoId, listadoTodasLasCategorias, filtrosBusqueda } = params
      const { skuPrincipal, situacion, marca, referenciaAgrupacion,
        categoriaNivelUno_Gama, categoriaNivelDos_Categoria_Agrupada, categoriaNivelDos_Categoria_Definida } = filtrosBusqueda || {}

      let registrosFinales = []

      const filterCatalogoProductos = {
        where: {
          and: [{ catalogoId }] // Filtra por el ID del catálogo
        }
      }
      const { data: dataCatalogoProductos } = await apiCatalogoProductos.catalogosProductosControllerFind(JSON.stringify(filterCatalogoProductos))

      const filterProducto = {
        where: {
          and: []
        }
      }
      // Extraemos los IDs de los productos que ya están en el catálogo
      const listaIdsProductos = dataCatalogoProductos.map(objeto => objeto.productoId)
      filterProducto.where.and.push({
        id: { inq: listaIdsProductos }  // Obtienen unicamente los productos que coinciden con los IDs
      })

      const filterProductoGeneral = {
        where: {
          and: []
        }
      }

      if (listaIdsProductos.length > 0) {
        const { data: dataProductos } = await apiProductos.productosControllerFind(JSON.stringify(filterProducto))
        filterProductoGeneral.where.and.push({
          productoId: { inq: listaIdsProductos }  // Obtienen unicamente los productos que coinciden con los IDs
        })

        const { data: dataProductosGeneral } = await apiProductosGeneral.productoGeneralControllerFind(JSON.stringify(filterProductoGeneral))
        // Función para filtrar
        const productosGeneralesFiltrados = dataProductosGeneral.filter(productoGeneral => {
          return (
            (!skuPrincipal || productoGeneral.sku.includes(skuPrincipal)) &&
            (!situacion || productoGeneral.estadoReferencia.includes(situacion)) &&
            (!marca || productoGeneral.marca.includes(marca)) &&
            (!referenciaAgrupacion || productoGeneral.referenciaAgrupacion.includes(referenciaAgrupacion))
          )
        })

        // Ejecución del filtro
        const productosFiltrados = await filtrarProductosPorCategoria(dataProductos, listadoTodasLasCategorias, categoriaNivelUno_Gama, categoriaNivelDos_Categoria_Definida, categoriaNivelDos_Categoria_Agrupada)
        console.log(productosFiltrados)
        // Combinar las listas
        const listaCombinada = combinarListas(productosGeneralesFiltrados, productosFiltrados)
        registrosFinales = listaCombinada

      } else {
        console.log('No hay productos en el catálogo')
        registrosFinales = []
      }

      const { data: dataProductosCatalgo } = await apiProductoDatosCatalogo.productoDatosCatalogoControllerFind()


      dispatch({
        type: GET_PRODUCTOS_PARA_CATALOGO,
        gestionProductosCatalogo: registrosFinales,        
        dataCatalogo: dataProductosCatalgo,
        total: registrosFinales.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_PRODUCTOS_PARA_CATALOGO,
        filtros: filtrosBusqueda
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const eliminarProductosDelCatalogo = (listaProductosCatalogo) => {
  return async () => {
    try {
      // Llamo a la API para eliminar múltiples productos del catálogo
      const { data } = await apiCatalogoProductos.catalogosProductosControllerEliminarMultiplesProductosDelCatalogo(listaProductosCatalogo)

      // Verifico si la respuesta contiene la propiedad deletedCount
      if (data && typeof data.deletedCount === 'number') {
        if (data.deletedCount > 0) {
          // Si se eliminaron productos
          return { status: 'OK', message: `${data.deletedCount} productos eliminados exitosamente`, data }
        } else {
          // Si no se eliminó ningún producto
          return { status: 'ERROR', message: 'No se eliminaron productos, quizás no coinciden con el catálogo' }
        }
      } else {
        // Si la respuesta no tiene la estructura esperada
        return { status: 'ERROR', message: 'Respuesta inesperada del servidor' }
      }
    } catch (err) {
      console.error(err.message)
      return { status: 'ERROR', message: `Error al eliminar productos: ${err.message}` }
    }
  }
}

export const obtenerSkuPrincipales = () => {
  return async () => {
    try {
      const { data: listaSKUs } = await apiSutitutos.sustitutosControllerObtenerSkuPrincipales()
      return listaSKUs
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerSituaciones = () => {
  return async () => {
    try {
      const { data: listaDeSituaciones } = await apiSituaciones.situacionesControllerFind()
      return listaDeSituaciones
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerReferenciasDeAgrupacion = () => {
  return async () => {
    try {
      const { data: listaReferenciasAgrupacios } = await apiCatalogoProductos.catalogosProductosControllerObtenerReferenciasDeAgrupacion()
      return listaReferenciasAgrupacios
    } catch (err) {
      console.error(err.message)
    }
  }
}
