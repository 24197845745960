import { 
  GET_SUSTITUTO,
  GET_SUSTITUTOS,
  GET_SUSTITUTOS_ALL,
  GET_SUSTITUTOS_COUNT,
  ADD_SUSTITUTO,
  DELETE_SUSTITUTO,
  UPDATE_SUSTITUTO,
  GET_FILTROS_SUSTITUTOS } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  sustitutos: [],
  selectedsustitutoId: 1,
  params: {},
  processing: false,
  filtros: {}
}

const prefix = 'sustitutos'

const sustitutos = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_SUSTITUTOS:
      return {
        ...state,
        data: action.sustitutos,
        total: action.total || 0,
        processing: false
      }
      case GET_SUSTITUTOS_ALL:
      return {
          ...state,
          sustitutos: action.sustitutos,
          processing: false
      }
     case GET_SUSTITUTO:
       return { ...state, selectedSustituto: action.selectedSustituto }
    case ADD_SUSTITUTO:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_SUSTITUTO:
      return { ...state, data: [...state.data].filter(sustituto => sustituto.id !== action.data)}
    case GET_SUSTITUTOS_COUNT:
      return { ...state, total: action.total }
    case UPDATE_SUSTITUTO:
      return { ...state, data: state.data.map(sustituto => (sustituto.id === action.data.id ? action.data : sustituto)) }
    case GET_FILTROS_SUSTITUTOS:
      return {
        ...state,
        filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default sustitutos
