import { EmpresasControllerApi, EmpresasBCControllerApi, MarcaControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'empresas'

export const GET_EMPRESA = `${prefix}/GET_DATA_EMPRESA`
export const GET_EMPRESAS = `${prefix}/GET_DATA`
export const GET_EMPRESAS_ALL = `${prefix}/GET_DATA_ALL`
export const GET_EMPRESAS_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_EMPRESA = `${prefix}/ADD`
export const DELETE_EMPRESA = `${prefix}/DELETE`
export const UPDATE_EMPRESA = `${prefix}/UPDATE`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiEmpresas = new EmpresasControllerApi(settings)
const apiEmpresasBC = new EmpresasBCControllerApi(settings)
const apimarcas = new MarcaControllerApi(settings)

// ** Get all Data

export const getData = params => {
  return async (dispatch, getState) => {
    try {

      const {page = 1, perPage = 10, sortBy = "", q = "" } = params
      const empresaId = getState().layout.selectedEmpresaId
    
      const filter =  {
        offset: (page - 1) * perPage,
        limit: perPage,
        skip: ((page - 1) * perPage),
        order: sortBy || "nombre ASC",
        where: {
          ['empresaId']: {eq: `${empresaId}`},
          ['or'] : [
            {['nombre']: {like: `%${q}%`, options: 'i'}},
            {['codigo']: {like: `%${q}%`, options: 'i'}}
          ]
        }
      }
      const {data: dataEmpresas} = await apiEmpresas.empresasControllerFind(JSON.stringify(filter))
      const {data: dataEmpresasCount} = await apiEmpresas.empresasControllerCount(JSON.stringify(filter.where))

      dispatch({
        type: GET_EMPRESAS,
        empresas: dataEmpresas,
        total: dataEmpresasCount.count || 0
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addEmpresa = (empresa, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiEmpresas.empresasControllerCreate(empresa)
      displaySuccessMsg(intl.formatMessage({ id: 'Empresa creada' }))
      dispatch({
         type: ADD_EMPRESA,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const updateEmpresa = (id, empresa, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiEmpresas.empresasControllerUpdateById(id, empresa)
      displaySuccessMsg(intl.formatMessage({ id: 'Empresa actualizada' }))
      dispatch({
         type: UPDATE_EMPRESA,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteEmpresa = (empresaId, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiEmpresas.empresasControllerDeleteById(empresaId)
      displaySuccessMsg(intl.formatMessage({ id: 'Empresa borrada' }))
       dispatch({
         type: DELETE_EMPRESA,
         data: empresaId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getEmpresa = (empresa) => {
  return async (dispatch) => {
    try {
      const {data: dataEmpresa} = await apiEmpresas.empresasControllerFindById(empresa)
      dispatch({
        type: GET_EMPRESA,
        selectedEmpresa: dataEmpresa
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initEmpresa = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_EMPRESA,
      selectedCompany: null
    })
  }
}

//O B T I E N E - L A S - E M P R E S A S - BC
export const getEmpresasBC = (idEmpresa) => {
  return async () => {
    const filter = {
      offset: (1 - 1) * 20,
      limit: 20,
      skip: ((1 - 1) * 20),
      //order: sortBy || "nombre ASC",
      where: {
        ['idEmpresa']: { eq: `${idEmpresa}` }
      }
    }
    const { data: dataEmpresasBC } = await apiEmpresasBC.empresasBCControllerFind(JSON.stringify(filter))
    const { data: dataMarcas } = await apimarcas.marcaControllerFind()

    // Recorro la lista de empresas bc y agrego el campo nombreMarca
    const listaEmpresasBCConNombreMarca = dataEmpresasBC.map(empresaBC => {
      const marca = dataMarcas.find(marca => marca.id === empresaBC.idMarca)
      if (marca) {
        return { ...empresaBC, nombreMarca: marca.nombre, codigoMarca: marca.codigo }
      }
      return empresaBC
    })

    console.log(listaEmpresasBCConNombreMarca)
    return listaEmpresasBCConNombreMarca
  }
}

//O B T I E N E - L A  - E M P R E S A S - BC
export const getEmpresaBC = (idEmpresaBC) => {
  return async () => {
    try {
      const {data: dataEmpresaBC} = await apiEmpresasBC.empresasBCControllerFindById(idEmpresaBC)
      return dataEmpresaBC
    } catch (err) {
      console.error(err.message)
    }
  }
}

//O B T I E N E - L AS - M A R C A S
export const getMarcas = () => {
  return async () => {
    try {
      const { data: dataMarcas } = await apimarcas.marcaControllerFind()
      return dataMarcas
    } catch (err) {
      console.error(err.message)
    }
  }
}

//C R E A R - E M P R E S A - BC
export const addEmpresaBC = (empresa, intl) => {
  return async () => {
    try {
      const {data} = await apiEmpresasBC.empresasBCControllerCreate(empresa)
      displaySuccessMsg(intl.formatMessage({ id: 'Registro creado correctamente!!' }))
      return data
    } catch (err) {
      console.error(err.message)
    }
  }
}

//A C T U A L I Z A - E M P R E S A - BC
export const updateEmpresaBC = (id, empresa, intl) => {
  return async () => {
    try {
      const {data} = await apiEmpresasBC.empresasBCControllerUpdateById(id, empresa)
      displaySuccessMsg(intl.formatMessage({ id: 'Registro actualizado correctamente!!' }))
      return data
    } catch (err) {
      console.error(err.message)
    }
  }
}

//E L I M I N A - E M P R E S A - BC
export const deleteEmpresaBC = (empresaId, intl) => {
  return async () => {
    try {
      const {data} = await apiEmpresasBC.empresasBCControllerDeleteById(empresaId)
      displaySuccessMsg(intl.formatMessage({ id: 'Registro eliminado correctamente!!' }))
      return data
    } catch (err) {
      console.error(err.message)
    }
  }
}