import { 
  GET_ICONO,
  GET_ICONOS,
  GET_ICONOS_ALL,
  GET_ICONOS_COUNT,
  ADD_ICONO,
  DELETE_ICONO,
  UPDATE_ICONO } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  iconos: [],
  selectedIcono: {},
  params: {},
  processing: false
}

const prefix = 'iconos'

const iconos = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_ICONOS:
      return {
        ...state,
        data: action.iconos,
        total: action.total || 0,
        processing: false
      }
      case GET_ICONOS_ALL:
      return {
          ...state,
          iconos: action.iconos,
          processing: false
      }
     case GET_ICONO:
       return { ...state, selectedIcono: action.selectedIcono }
    case ADD_ICONO:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_ICONO:
      return { ...state, data: [...state.data].filter(icono => icono.id !== action.data)}
    case GET_ICONOS_COUNT:
      return { ...state, total: action.total }
    case UPDATE_ICONO:
      return { ...state, data: state.data.map(icono => (icono.id === action.data.id ? action.data : icono)) }
    default:
      return { ...state }
  }
}
export default iconos
