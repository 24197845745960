import { 
  GET_PRODUCTO_POR_RANGO,
  GET_PRODUCTOS_POR_RANGO,
  GET_PRODUCTOS_POR_RANGO_ALL,
  GET_PRODUCTOS_POR_RANGO_COUNT,
  ADD_PRODUCTO_POR_RANGO,
  DELETE_PRODUCTO_POR_RANGO,
  UPDATE_PRODUCTO_POR_RANGO,
  GET_FILTROS_PRODUCTOS_POR_RANGO } from "../action"

// ** Initial State
const initialState = {
  data: [],
  allData: [],
  selectedProducto: {},
  gruposAtributos: [],
  atributos: [],
  categorias: [],
  familias: [],
  productosPorRango: [],
  selectedProductoPorRangoId: 1,
  params: {},
  processing: false,
  filtros: {}
}

const prefix = 'productosPorRango'

const productosPorRango = (state = initialState, action) => {
  switch (action.type) {
    case `${prefix}/GET_ALL_DATA`:
      return { ...state, allData: action.data }
      case GET_PRODUCTOS_POR_RANGO:
      return {
        ...state,
        data: action.productosPorRango,
        total: action.total || 0,
        processing: false
      }
      case GET_PRODUCTOS_POR_RANGO_ALL:
      return {
          ...state,
          productosPorRango: action.productosPorRango,
          processing: false
      }
     case GET_PRODUCTO_POR_RANGO:
       return { ...state, selectedProductoPorRango: action.selectedProductoPorRango }
    case ADD_PRODUCTO_POR_RANGO:
      return { ...state, data: state.data.concat(action.data) }
    case DELETE_PRODUCTO_POR_RANGO:
      return { ...state, data: [...state.data].filter(productoPorRango => productoPorRango.id !== action.data)}
    case GET_PRODUCTOS_POR_RANGO_COUNT:
      return { ...state, total: action.total }
    case UPDATE_PRODUCTO_POR_RANGO:
      return { ...state, data: state.data.map(productoPorRango => (productoPorRango.id === action.data.id ? action.data : productoPorRango)) }
    case GET_FILTROS_PRODUCTOS_POR_RANGO:
      return {
        ...state,
        filtros: action.filtros
      }
    default:
      return { ...state }
  }
}
export default productosPorRango
